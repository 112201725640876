<template>
    <GeneralLedgerDetails/>
</template>

<script>
import GeneralLedgerDetails from '@/components/content/content-acc/GeneralLedgerDetails.vue';
export default {
    components: {
        GeneralLedgerDetails
    },
    data() {
        return {
            
        }
    }

}
</script>