<template>
	<!-- <div v-if="hasPermission"> -->
		<div>
			
			<ContentConsolidated />
		</div>
	<!-- </div> -->
</template>

<script>
// @ is an alias to /src
import ContentConsolidated from '@/components/content/content-acc/ContentConsolidated.vue'
import { getPermissions } from '../components/util/liblist'

export default {
	name: 'Consolidated',
	components: {
		ContentConsolidated,
	},
	data() {
		return {
			'hasPermission': false, 
		}
	},
	mounted() {
		if (getPermissions('accounting.report.consolidated')) {
			this.hasPermission = true
		}
	},
	computed: {

	},
	methods: {

	},
}
</script>
