<template>
    <div class="container px-6 mx-auto dark:bg-gray-900">
        <div class="grid grid-cols-3 gap-4">
			<div>
				<h2
					class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200 text-left"
				>
				Libro Mayor
				</h2>
			</div>
		</div>
        <div>
            <form @submit.prevent="getAccountsWithTransactions" autocomplete="off">	
                <div class="grid grid-cols-2 gap-4 items-center p-4 bg-white rounded-lg shadow dark:bg-gray-800" >
                        <div class="grid grid-cols-1">
                            <div class="text-left">
                                Desde: <input type="date" v-model="fromDate" class="border-2 border-gray-300 rounded">							
                                Hasta: <input type="date" v-model="toDate" class="border-2 border-gray-300 rounded">
                            </div>
                        </div>
                        <div class="text-right">
                            <button type="submit"  class="px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                                Buscar
                            </button>					
                        </div>	
                </div>
                
            </form>
        </div>
        <div v-if="errorMessage != ''" class="w-full bg-red-500 p-2 mt-1 mb-2 text-left rounded-lg shadow font-bold text-white">{{ errorMessage }}</div>
        <div v-if="loading" class="w-full bg-white p-2 mt-1 mb-2 text-left rounded-lg shadow">
            Cargando...
        </div> 
        <div v-else v-for="(account, index) in accounts" :key="index" class="w-full bg-white p-2 mt-1 mb-2 text-left rounded-lg shadow">
            <div class="mb-2 font-bold flex">
                <div class="w-4/6" @click="showIndex.index = index">
                    Cuenta
                </div>
                <div class="w-1/12 text-right"></div>
                <div class="w-1/12 text-right">Debito</div>
                <div class="w-1/12 text-right">Credito</div>
                <div class="w-1/12 text-right">Saldo</div>
            </div>
            <div class=" border-b-2 border-gray-300 flex">
                <div  @click="showDetail(index)" class="w-9/12 cursor-pointer font-bold">
                    {{account.accountCode}} &nbsp;&nbsp;{{account.accountName}}
                </div>
                <div class="w-1/12 text-right">
                    <div v-if="account?.previous_amounts?.cumulative_previous_debit" class="bg-200 py-1 font-semibold w-full">
                        Acumulado
                    </div>
                    <div class="font-bold py-1  w-full">
                        Actual
                    </div>
                </div>
                <div class="w-1/12 text-right">
                    <div v-if="account?.previous_amounts?.cumulative_previous_debit" class="bg-200 py-1 font-semibold w-full">
                        {{ formattedAmount(account?.previous_amounts?.cumulative_previous_debit) }}
                    </div>
                    <div class="font-bold py-1  w-full">
                        {{ formattedAmount(account.total_debit) }}
                    </div>
                </div>
                <div class="w-1/12 text-right">
                    <div v-if="account?.previous_amounts?.cumulative_previous_credit" class=" py-1 font-semibold w-full">
                        {{ formattedAmount(account?.previous_amounts?.cumulative_previous_credit) }}
                    </div>
                    <div class="font-bold py-1  w-full">
                        {{ formattedAmount(account.total_credit)}}
                    </div>
                </div>
                <div class="w-1/12 text-right">
                    <div v-if="account?.previous_amounts?.cumulative_previous_balance" class=" py-1 font-semibold w-full">
                        {{ formattedAmount(account?.previous_amounts?.cumulative_previous_balance) }}
                    </div>
                    <div class="font-bold py-1  w-full">
                        {{ formattedAmount(account.total_balance) }}
                    </div>
                </div>
            </div>
            <!-- <div class="border-b-2 border-gray-300 ml-6 flex">
                <div class="w-1/12">Fecha</div>
                <div class="w-4/6">Descripc&iacute;on</div>
                <div class="w-1/12">Debito</div>
                <div class="w-1/12">Credito</div>
                <div class="w-1/12">Saldo</div>
            </div> -->
            <div v-show="index == showIndex[index]" v-for="(transaction, indexTransaction) in account.account_transactions" :key="indexTransaction" class=" bg-white ml-6 flex">
                <div class="w-1/12 border-l-2 border-r-2 border-b-2 border-gray-300">{{formattedDate(transaction.transactionDate)}}</div>
                <div class="w-4/6 border-r-2 border-b-2 border-gray-300">{{transaction.description}}</div>
                <div class="w-1/12 border-r-2 border-b-2 border-gray-300 text-right">{{formattedAmount(transaction.debit)}}</div>
                <div class="w-1/12 border-r-2 border-b-2 border-gray-300 text-right">{{formattedAmount(transaction.credit)}}</div>
                <div class="w-1/12 border-r-2 border-b-2 border-gray-300 text-right">{{formattedAmount(transaction.progressive_balance)}}</div>
            </div>
            
        </div>
        
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import axios from 'axios';
    export default {
        name: "QuickInvoice",
        data() {
            return {
                URL: process.env.VUE_APP_URL_ACC,
                objGerenalLeger: {},
                fromDate: '2023-01-01',
                toDate: '2023-12-31',
                accounts:[],
                format: 'US', // puedes cambiar esto a 'VE' para el formato de Venezuela
                currency: 'US', // puedes cambiar esto a 'VE' para la moneda de Venezuela
                showIndex: [],
                loading: false,
                errorMessage: '',
            }
        },
        mounted() {
            // this.getAccountsWithTransactions();
        },
        computed: {
			...mapState(['user','access_token','year']),
            
		},
        methods: {
            formattedDate(date) {
                let dateObj = new Date(date)
                let day = dateObj.getDate().toString().padStart(2, '0')
                let month = (dateObj.getMonth() + 1).toString().padStart(2, '0') // los meses en JavaScript empiezan en 0
                let year = dateObj.getFullYear()

                if (this.format === 'US') {
                    return `${month}/${day}/${year}` // formato MM/DD/YYYY
                } else {
                    return `${day}/${month}/${year}` // formato DD/MM/YYYY
                }
            },
            formattedAmount(balance) {
                let amount = parseFloat(balance)
                if (amount == 0) {
                    return '' // si el monto es cero, retorna una cadena vacía
                }

                let isNegative = amount < 0
                let absoluteAmount = Math.abs(amount)

                let formattedAmount
                if (this.currency === 'US') {
                    // Formato de moneda de EE.UU.: $1,234.56
                    formattedAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(absoluteAmount)
                } else {
                    // Formato de moneda de Venezuela: Bs.S 1.234,56
                    formattedAmount = new Intl.NumberFormat('es-VE', { style: 'currency', currency: 'VES' }).format(absoluteAmount)
                }

                if (isNegative) {
                    // si el monto es negativo, coloca el monto entre paréntesis
                    formattedAmount = `(${formattedAmount})`
                }

                return formattedAmount
            },
            showDetail(index){
                // console.log(index);
                // this.showIndex = index;
                let found = this.showIndex.includes(index)
                if (found) {
                    this.$set(this.showIndex, index, -1)
                    // this.showIndex.splice(index, 1)
                    // this.showIndex.pop(index)
                }else{
                    this.$set(this.showIndex, index, index)
                }
                console.log(this.showIndex);
            },
            async getAccountsWithTransactions(){
                this.errorMessage = ''
                this.loading = !this.loading
                try {
                    console.log("object");
                    const url = `${this.URL}getAccountsWithTransactions`;
                    let params = {
                            company_id: this.user.companyId,
                            company: this.user.companyId,
                            year: this.year,
                            fromDate: this.fromDate,
                            toDate: this.toDate,
                    }
                    const fetchConfig = {
                        headers: {
                            Accept : 'application/json',
                            "Content-Type": 'application/json',
                            // Authorization : `Bearer ${this.access_token.replace(/['"]+/g, '')}`	
                        }
                    }
                    const response = await axios.post(url, params, fetchConfig);
                    this.accounts = response.data.data
                    console.log(this.accounts);
                } catch (error) {
                    console.log(error);
                    console.error("Error al cargar datos:", error);
                    if (error.response) {
                        this.errorMessage = "Error de API: " + error.response.data.message;
                    } else if (error.request) {
                        this.errorMessage = "Error de conexión: No se pudo comunicar con el servidor.";
                    } else {
                        this.errorMessage = "Error desconocido. Por favor, inténtalo de nuevo más tarde.";
                    }
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>