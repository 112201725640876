<template>
  <div class="container grid px-6 mx-auto dark:bg-gray-900">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    >
    </loading>
    <h2
      class="my-6 text-2xl font-semibold text-left text-gray-700 dark:text-gray-200"
    >
      Consolidado de Empresas
    </h2>
    <div class="w-full text-sm text-left">
      Desde:
      <input
        type="date"
        v-model="dateFrom"
        class="border-2 border-gray-300 rounded"
      />
      Hasta:
      <input
        type="date"
        v-model="currentDate"
        class="border-2 border-gray-300 rounded"
      />
      <button
        @click="getConsolidated()"
        class="px-4 py-2 ml-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple"
      >
        Generar
      </button>
      <button
        @click="exportarPDF()"
        class="px-4 py-2 ml-2 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg active:bg-blue-500 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple"
      >
        Exportar PDF
      </button>

      <div class="w-full overflow-x-auto">
        <div class="grid gap-3 mb-8 md:grid-cols-2 lg:grid-cols-2">
          <div>
            <!-- Card -->
            <div
              class="mb-3 flex p-4 bg-white rounded-lg shadow dark:bg-gray-800"
            >
              <div
                class="py-1 px-2 mr-4 font-semibold text-gray-700 dark:text-gray-200 text-center justify-start"
              >
                Empresas:
                <ul>
                  <li
                    v-for="(item, index) in oCountry"
                    :key="index"
                    class="text-left text-xs font-semibold"
                  >
                    <button
                      type="button"
                      @click.prevent="getCompanyByCountry(index)"
                      class="hover:underline"
                    >
                      {{ item.country }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="text-sm text-gray-800">
                <ul>
                  <li v-for="(item, index) in oCompanies.companys" :key="index">
                    <button
                      type="button"
                      @click.prevent="addCompanyObject(item, index)"
                      class="hover:underline text-left"
                    >
                      {{ item.companyName }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div>
            <!-- Card Graphs -->
            <div
              class="flex block-inline p-4 bg-white rounded-lg shadow dark:bg-gray-800"
            >
              <div
                class="py-1 px-2 mr-4 font-semibold text-gray-700 dark:text-gray-200 text-center"
              >
                A Consultar:
              </div>
              <div class="text-sm text-gray-800">
                <ul>
                  <li v-for="(item, index) in oAddCompanies" :key="index">
                    <button
                      type="button"
                      @click.prevent="removeCompanyObject(item, index)"
                      class="hover:underline text-left"
                    >
                      {{ item.companyName }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead v-if="objectData?.data?.data?.length > 0">
            <tr
              class="text-xs font-semibold tracking-wide text-white uppercase border-b dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800 bg-blue-500"
            >
              <th class="px-2 py-1 w-24">Grupo</th>
              <th class="px-2 py-1 w-64">Cuenta</th>
              <th class="px-2 py-1 w-60">Debito</th>
              <th class="px-2 py-1">Credito</th>
              <th class="px-2 py-1 text-right w-24">Total</th>
            </tr>
          </thead>
          <tbody
            v-if="
              typeof objectData !== 'undefined' &&
              typeof objectData.data !== 'undefined' &&
              typeof objectData.data.data !== 'undefined' &&
              objectData.data.data.length > 0
            "
            class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800"
          >
            <!-- <tr v-for="(item, index) in objectData?.data?.data" :key="index" class="text-gray-700 dark:text-gray-400"> -->
            <tr
              v-for="(item, index) in typeof typeof objectData.data.data !==
              'undefined'
                ? objectData.data.data
                : []"
              :key="index"
              class="text-gray-700 dark:text-gray-400"
            >
              <td class="px-2 py-1 text-sm text-left w-56">
                {{
                  typeof item.accountGroup !== "undefined"
                    ? item.accountGroup
                    : ""
                }}
              </td>

              <td class="px-2 py-1 text-sm text-left w-56">
                {{
                  typeof item.accountGroup !== "undefined"
                    ? item.accountGroup
                    : ""
                }}
              </td>

              <td class="px-2 py-1 text-xs w-28">
                {{
                  typeof item.debit !== "undefined"
                    ? formatNumber(item.debit, ",", "$", true)
                    : ""
                }}
              </td>

              <td class="px-2 py-1 text-xs text-left w-60">
                {{
                  typeof item.credit !== "undefined"
                    ? formatNumber(item.credit, ",", "$", true)
                    : ""
                }}
                <div
                  v-if="
                    typeof item.net_salary !== 'undefined'
                  "
                  class="text-sm semibold underline"
                >
                  Saldo:
                </div>
              </td>
              <td class="px-2 py-1 text-sm text-right">
                {{
                  typeof item.total !== "undefined"
                    ? formatNumber(item.total, ",", "$", true)
                    : ""
                }}
                {{
                  typeof item.net_salary !== "undefined" && item.net_salary > 0
                    ? formatNumber(item.net_salary, ",", "$", true)
                    : `(` + formatNumber(item.net_salary, ",", "$", true) + `)`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <!-- <canvas ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas> -->
    </div>

    <!-- <button
                  v-on:click="hpdf()"
                  title="ExportarPDF"
                  class="px-2 py-2 font-medium leading-5 text-white transition-colors duration-150 bg-green-400 border border-transparent rounded-md active:bg-blue-600 hover:bg-green-500 focus:outline-none focus:shadow-outline-purple"
                >
                  <i class="mr-2 icon-arrows-cw" aria-hidden="true"></i> Exportar PDF
                </button> -->
  </div>
</template>

<script>
import Axios from "axios";
import { mapState, Store } from "vuex";
// import moment from "moment";
// import { jsPDF } from "jspdf";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
import jsPDF from "jspdf";
export default {
  name: "NumberAccTransactions",
  components: {
    Loading,
  },
  props: {},
  data() {
    return {
      URL: process.env.VUE_APP_URL_ACC,
      canvasWidth: 595, // A4 width in pixels
      canvasHeight: 842, // A4 height in pixels
      objectData: [],
      oCountry: [],
      oCompanies: [],
      oAddCompanies: [],
      oCompaniesSend: [],
      dateFrom: "",
      dateTo: "",
      currentDate: moment().format("YYYY-MM-DD"),
      isLoading: false,
      fullPage: "",
    };
  },
  computed: {
    ...mapState(["user", "year", "access_token"]),
  },
  mounted() {
    this.getContryCompany();
    this.getYearDate();
  },

  methods: {
    // async exportarPDF() {
    //   this.isLoading = true;
    //   setTimeout(() => {
    //     this.isLoading = false;
    //   }, 2000);
    //   if (this.oCompaniesSend.length < 2) {
    //     alert("Ingrese al menos 2 empresas.");
    //   } else {
    //     const URL = `${this.URL}show-consolidate`;
    //     const fetchConfig = {
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //       },
    //     };

    //     let params = {
    //       companies: this.oCompaniesSend,
    //       dateFrom: this.dateFrom,
    //       dateTo: this.currentDate,
    //     };
    //     this.objectData = await Axios.post(URL, params, fetchConfig);
    //     const datos = this.objectData.data.data;

    //     function formatearFecha(fecha) {
    //       var partes = fecha.split("-");
    //       var anio = partes[0];
    //       var mes = partes[1];
    //       var dia = partes[2];

    //       // Crear una nueva fecha en formato DD-MM-YYYY
    //       var fechaFormateada = dia + "-" + mes + "-" + anio;

    //       return fechaFormateada;
    //     }

    //     const doc = new jsPDF("p", "pt", "letter");
    //     const tableHeader = ["Grupo", "Credito", "Debito", "Total"];

    //     let startX = 100; // Definir posición inicial
    //     let posY = 70;
    //     const columnWidth = 100; // Definir ancho de columna
    //     doc.setFontSize(10); // Definir estilo del texto
    //     doc.setFont("helvetica", "bold");

    //     console.log("this.oAddCompanies");
    //     console.log(this.oAddCompanies);

    //     const title = "ESTADO DE RESULTADO CONSOLIDADO";
    //     const desde = "Desde: ";
    //     const hasta = "Hasta: ";
    //     const titleWidth =
    //       doc.getStringUnitWidth(title) * doc.internal.getFontSize(); // Obtener el ancho del texto en unidades PDF
    //     const desdeWidth =
    //       doc.getStringUnitWidth(desde) * doc.internal.getFontSize();
    //     const headerCol1Width =
    //       doc.getStringUnitWidth(tableHeader[0]) * doc.internal.getFontSize();
    //     const hastaWidth =
    //       doc.getStringUnitWidth(hasta) * doc.internal.getFontSize();
    //     const pageWidth = doc.internal.pageSize.getWidth(); // Obtener el ancho de la página
    //     const titleX = (pageWidth - titleWidth) / 2; // Calcular la posición X para centrar el texto

    //     doc.text(title, titleX, posY);
    //     posY += 45;

    //     // Dibujar desde y hasta
    //     doc.text(startX, posY, `${"Desde: "}`, {
    //       align: "left",
    //     });
    //     doc.setFont("helvetica", "normal");
    //     doc.text(
    //       startX + desdeWidth,
    //       posY,
    //       `${formatearFecha(this.dateFrom)}`,
    //       {
    //         align: "left",
    //       }
    //     );
    //     posY += 15;
    //     doc.setFont("helvetica", "bold");
    //     doc.text(startX, posY, `${"Hasta: "}`, {
    //       align: "left",
    //     });
    //     doc.setFont("helvetica", "normal");
    //     doc.text(
    //       startX + hastaWidth,
    //       posY,
    //       `${formatearFecha(this.currentDate)}`,
    //       {
    //         align: "left",
    //       }
    //     );
    //     posY += 30; // salto de linea

    //     // listar comapanys
    //     doc.setFont("helvetica", "bold");
    //     doc.text(startX, posY, `${"COMPAÑÍAS:"}`, {
    //       align: "left",
    //     });
    //     posY += 30;
    //     doc.setFont("helvetica", "normal");
    //     this.oAddCompanies.forEach((company) => {
    //       const companyName = company.companyName;
    //       doc.text(companyName, startX, posY, { align: "left" });
    //       posY += 15; // Incrementar la posición en y para el siguiente nombre
    //     });

    //     // Dibujar encabezado
    //     posY += 15;
    //     startX += headerCol1Width;
    //     doc.setFont("helvetica", "bold");
    //     tableHeader.forEach((header, index) => {
    //       doc.text(startX + index * columnWidth, posY, header, {
    //         align: "right",
    //       });
    //     });

    //     // Datos usados para las líneas
    //     const tableWidth = 400;
    //     const lineStartX = startX - headerCol1Width;
    //     const lineEndX = tableWidth;

    //     // Dibujar línea debajo del encabezado
    //     posY += 5;
    //     doc.setLineWidth(0.5); // Establecer el ancho de línea
    //     doc.line(lineStartX, posY, lineEndX + headerCol1Width, posY);

    //     // Currency formatter
    //     const formatter = new Intl.NumberFormat("en-US", {
    //       style: "currency",
    //       currency: "USD",
    //     });

    //     //Dibujar datos de la fila
    //     doc.setFont("helvetica", "normal");
    //     posY += 15; // Adjusted to be two rows above the data rows
    //     let numRowsToDraw = datos.length-1;
    //     //const numRowsToDraw = Math.min(3, datos.length); // Draw the first three rows or less if there are fewer than three rows
    //     for (let i = 0; i < numRowsToDraw; i++) {
    //       const row = datos[i];
    //       const rowData = Object.values(row);
    //       rowData.forEach((value, index) => {
    //         if (index === 1 || index === 2 || index === 3) {
    //           doc.text(
    //             startX + index * columnWidth,
    //             posY,
    //             formatter.format(value),
    //             {
    //               align: "right",
    //             }
    //           );
    //         } else {
    //           doc.text(startX + index * columnWidth, posY, value.toString(), {
    //             align: "right",
    //           });
    //         }
    //       });
    //       posY += 15;
    //     }

    //     // Dibujar liena encima del Saldo
    //     posY -= 10;
    //     doc.setLineWidth(0.5);
    //     doc.line(lineStartX, posY, lineEndX + headerCol1Width, posY);

    //     // Obtener Saldo
    //     const saldoP = parseInt(datos.length)-1;
    //     console.log(saldoP);
    //     const fourthRowValue = datos[saldoP].net_salary;

    //     // Mostrar el Saldo aliienado con la última columna
    //     doc.setFont("helvetica", "bold");
    //     let fourthRowValueD = 0.0;
    //     if (fourthRowValue < 0) {
    //       fourthRowValueD = Math.abs(fourthRowValue);
    //       doc.text(
    //         startX + 300,
    //         posY + 15,
    //         `${
    //           "Saldo: (" + formatter.format(fourthRowValueD.toString()) + ")"
    //         }`,
    //         {
    //           align: "right",
    //         }
    //       );
    //     } else {
    //       doc.text(
    //         startX + 300,
    //         posY + 15,
    //         `${"Saldo: " + formatter.format(fourthRowValue.toString())}`,
    //         {
    //           align: "right",
    //         }
    //       );
    //     }
    //     doc.save("Consolidado - " + moment().format("DD-MM-YYYY") + ".pdf");
    //   }
    // },

    async exportarPDF() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
      if (this.oCompaniesSend.length < 2) {
        alert("Ingrese al menos 2 empresas.");
      } else {
        const URL = `${this.URL}show-consolidate`;
        const fetchConfig = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };

        let params = {
          companies: this.oCompaniesSend,
          dateFrom: this.dateFrom,
          dateTo: this.currentDate,
        };
        this.objectData = await Axios.post(URL, params, fetchConfig);
        const datos = this.objectData.data.data;

        function formatearFecha(fecha) {
          var partes = fecha.split("-");
          var anio = partes[0];
          var mes = partes[1];
          var dia = partes[2];
          // Crear una nueva fecha en formato DD-MM-YYYY
          var fechaFormateada = dia + "-" + mes + "-" + anio;
          return fechaFormateada;
        }

        // Currency formatter
        const formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        });

        const doc = new jsPDF("p", "pt", "letter");
        const tableHeader = ["Grupo", "Credito", "Debito", "Total"];

        let startX = 100; // Definir posición inicial
        let posY = 70;
        const columnWidth = 100; // Definir ancho de columna
        doc.setFontSize(10); // Definir estilo del texto
        doc.setFont("helvetica", "bold");

        console.log("datos");
        console.log(datos);

        const title = "ESTADO DE RESULTADO CONSOLIDADO";
        const desde = "Desde: ";
        const hasta = "Hasta: ";
        const gastos = "Ingresos Totales";
        const titleWidth =
          doc.getStringUnitWidth(title) * doc.internal.getFontSize(); // Obtener el ancho del texto en unidades PDF
        const desdeWidth =
          doc.getStringUnitWidth(desde) * doc.internal.getFontSize();
        const headerCol1Width =
          doc.getStringUnitWidth(tableHeader[0]) * doc.internal.getFontSize();
        const gastosWidth =
          doc.getStringUnitWidth(gastos) * doc.internal.getFontSize();
        const hastaWidth =
          doc.getStringUnitWidth(hasta) * doc.internal.getFontSize();
        const pageWidth = doc.internal.pageSize.getWidth(); // Obtener el ancho de la página
        const titleX = (pageWidth - titleWidth) / 2; // Calcular la posición X para centrar el texto

        doc.text(title, titleX, posY); // Dibujar Titulo

        // Dibujar desde y hasta
        doc.text(
          startX + 120,
          posY + 15,
          `${
            "Desde: " +
            formatearFecha(this.dateFrom) +
            " " +
            "Hasta: " +
            formatearFecha(this.currentDate)
          }`,
          {
            align: "left",
          }
        );
        posY += 60; // salto de linea

        // listar comapanys
        doc.setFont("helvetica", "bold");
        doc.text(startX, posY, `${"Empresas consultadas:"}`, {
          align: "left",
        });
        posY += 30;
        doc.setFont("helvetica", "normal");
        this.oAddCompanies.forEach((company) => {
          const companyName = company.companyName;
          doc.text(companyName, startX, posY, { align: "left" });
          posY += 15; // Incrementar la posición en y para el siguiente nombre
        });

        // Datos usados para las líneas
        const tableWidth = 200;
        const lineStartX = startX;
        const lineEndX = tableWidth;

        const datosModificados = datos.map((objeto) => {
          const { debit, credit, ...objetoModificado } = objeto; // Desestructuración y omisión de las propiedades "debit" y "credit"
          const accountGroup = objetoModificado.accountGroup; // Obtiene el valor de la primera propiedad "accountGroup"
          // Realiza el reemplazo en base al valor de "accountGroup"
          if (accountGroup === 4) {
            objetoModificado.accountGroup = "Ingresos Totales";
          } else if (accountGroup === 5) {
            objetoModificado.accountGroup = "Costos Totales";
          } else if (accountGroup === 6) {
            objetoModificado.accountGroup = "Gastos";
          }
          return objetoModificado; // Devuelve el objeto modificado
        });

        console.log("datosModificados");
        console.log(datosModificados);

        // Obtener Saldo
        const saldoP = parseInt(datos.length) - 1;
        console.log(saldoP);
        const fourthRowValue = datos[saldoP].net_salary;

        let brute = datosModificados[0].total - datosModificados[1].total; // ultilida o perdida bruta
        
        let utilPerdida = "";
        let utilidadNeta = "";
        if (brute < 0) {
          utilPerdida = "Perdida bruta";
        } else {
          utilPerdida = "Utilidad bruta";
        }

        if (fourthRowValue < 0) {
          utilidadNeta = "Perdida neta";
        } else {
          utilidadNeta = "Utilidad neta";
        }

        // Crear una nueva fila con la variable "name" y el valor de "brute"
        let nuevaFila = { name: utilPerdida, total: brute };
        let filaVacia = { name: "", total: "" };
        let filaNeta = { name: utilidadNeta, total: fourthRowValue};

        // Insertar la nueva fila después de la segunda fila
        datosModificados.splice(2, 0, filaVacia);
        datosModificados.splice(2, 0, nuevaFila);
        datosModificados.splice(5, 0, filaNeta);

        //Dibujar datos de la fila
        doc.setFont("helvetica", "bold");
        posY += 15; // Adjusted to be two rows above the data rows
        let numRowsToDraw = datosModificados.length - 1;
        for (let i = 0; i < numRowsToDraw; i++) {
          const row = datosModificados[i];
          const rowData = Object.values(row);
          const value = rowData[0]; // Obtener el valor de la primera columna
          doc.text(startX, posY, value.toString(), {
            align: "left",
          });
          posY += 15;
        }
        doc.setFont("helvetica", "normal");
        startX += 400;
        posY -= 15 * numRowsToDraw;
        for (let i = 0; i < numRowsToDraw; i++) {
          const row = datosModificados[i];
          const rowData = Object.values(row);
          let value = rowData[1]; // Obtener el valor de la segunda columna
          if (value != "" || value.length != 0) {
            if (value < 0) {
              value = Math.abs(value);

              doc.text(
                startX,
                posY,
                `${"(" + formatter.format(value?.toString()) + ")"}`,
                {
                  align: "right",
                }
              );
              posY += 15;
            } else {
              doc.text(startX, posY, formatter.format(value?.toString()), {
                align: "right",
              });
              posY += 15;
            }
          }else{
            doc.text(startX, posY, value?.toString(), {
                align: "right",
              });
              posY += 15;
          }
        }

        // Dibujar lienas
        posY -= 27;
        startX -= 200;
        doc.setLineWidth(0.5);
        doc.line(lineStartX + 300, posY, lineEndX + 300, posY);
        posY -= 45;
        startX -= 200;
        doc.setLineWidth(0.5);
        doc.line(lineStartX + 300, posY, lineEndX + 300, posY);
        posY += 60;
        startX -= 200;
        doc.setLineWidth(0.5);
        doc.line(lineStartX + 300, posY, lineEndX + 300, posY);
        posY += 5;
        startX -= 200;
        doc.setLineWidth(0.5);
        doc.line(lineStartX + 300, posY, lineEndX + 300, posY);

        doc.save("Consolidado - " + moment().format("DD-MM-YYYY") + ".pdf");
      }
    },

    onCancel() {
      console.log("User cancelled the loader.");
    },

    formatNumber(number, separator = ",", symbol = "$", showSymbol = false) {
      number = parseFloat(number);
      number = number.toFixed(2);
      if (separator == ",") {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      } else {
        let montoNuevo = number
          .toString()
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{2})$/, "$1,$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        if (showSymbol) {
          return `${symbol}${montoNuevo}`;
        } else {
          return `${montoNuevo}`;
        }
      }
    },

    getYearDate() {
      this.dateFrom = `${this.year}-01-01`;
      // console.log(this.year)
    },

    onCancel() {
      console.log("User cancelled the loader.");
    },

    async getConsolidated() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
      if (this.oCompaniesSend.length < 2) {
        alert("Ingrese al menos 2 empresas.");
      } else {
        const URL = `${this.URL}show-consolidate`;
        const fetchConfig = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        };

        let params = {
          companies: this.oCompaniesSend,
          dateFrom: this.dateFrom,
          dateTo: this.currentDate,
        };

        this.objectData = await Axios.post(URL, params, fetchConfig);
        console.log("objectData");
        console.log(this.objectData);
        this.isLoading = false;
      }
    },

    async getContryCompany() {
      const URL = `${process.env.VUE_APP_URL}country-company`;

      const fetchConfig = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.access_token.replace(/['"]+/g, "")}`,
        },
      };

      try {
        let res = await Axios.get(URL, fetchConfig);
        // console.log('compania')
        this.oCountry = res.data.data.oCompany; // obtener el pais
        // console.log(this.oCountry)
      } catch (error) {
        Object.keys(error).forEach((key) => {
          console.log(error[key]);
        });
      }
      this.isLoading = false;
    },
    getCompanyByCountry(index) {
      // console.log(this.oCompanies = this.oCountry[index])
      this.oCompanies = this.oCountry[index];
    },
    addCompanyObject(item, index) {
      // console.log(item);
      this.oAddCompanies.push(item);
      this.oCompaniesSend.push(item.companyId);
      console.log(this.oCompanies.companys[index]);
      this.oCompanies.companys.splice(index, 1);
      // console.log( this.oCompaniesSend);
    },
    removeCompanyObject(item, index) {
      this.oAddCompanies.splice(index, 1);
      this.oCompaniesSend.splice(index, 1);
      this.oCompanies.companys.unshift(item);
      // console.log( this.oCompaniesSend);
    },
  },
  watch: {},
};
</script>

<style></style>
